import axios from '@axios'

export default {
  namespaced: true,
  state: {
    Utilisateurs: [],
    total: 0,
  },
  getters: {
    getUtilisateurs(state) {
      return state.Utilisateurs
    },
    getTotal(state) {
      return state.total
    },
  },
  mutations: {
    setUtilisateurs: (state, value) => {
      state.Utilisateurs = value
    },
    setTotal: (state, value) => {
      state.total = value
    },
  },
  actions: {
    fetchAll({ commit }, filter) {
      return new Promise((done, fail) => {
        axios
          .get(`/users?page=${filter.page}&limit=${filter.limit}`)
          .then(response => {
            let utilisateurs = []
            utilisateurs = response.data['hydra:member'].map(utilisateur => {
              const data = {
                value: utilisateur['@id'],
                label: utilisateur.email,
                id: utilisateur.id,
                nom: utilisateur.nom,
                prenom: utilisateur.prenom,
                societe: utilisateur.societe,
                email: utilisateur.email,
                emailSecondaire: utilisateur.emailSecondaire,
                etat: utilisateur.etat,
                uri: utilisateur['@id'],
                roles: utilisateur.roles,
                projet: (utilisateur.projet) ? utilisateur.projet : null,
              }
              return data
            })
            commit('setUtilisateurs', utilisateurs)
            commit('setTotal', response.data['hydra:totalItems'])
            done({ utilisateurs, total: response.data['hydra:totalItems'] })
          })
          .catch(error => fail(error))
      })
    },
    // eslint-disable-next-line no-empty-pattern
    insert({}, utilisateur) {
      return new Promise((done, fail) => {
        axios
          .post('/users', utilisateur)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    fetchOne({ commit }, id) {
      const url = `/users/${id}`
      return new Promise((done, fail) => {
        axios.get(url)
          .then(response => {
            done(response.data)
          })
          .catch(err => fail(err))
      })
    },
    update({ commit }, data) {
      return new Promise((done, fail) => {
        const url = `/users/${data.id}`
        const userData = {
          nom: data.nom,
          prenom: data.prenom,
          roles: data.roles,
          email: data.email,
          etat: data.etat,
          societe: data.societe,
          emailSecondaire: data.emailSecondaire,
        }
        if (data.projet) {
          userData.projet = data.projet['@id']
        }

        axios.patch(url, userData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(() => { done(true) })
          .catch(err => fail(err))
      })
    },
    newFromAdmin({ commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/auth/newFromAdmin', data)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    updateUserData({ commit }, data) {
      return new Promise((done, fail) => {
        const url = `/users/${data.id}`
        const userData = { }
        if (data.nom !== undefined) userData.nom = data.nom
        if (data.prenom !== undefined) userData.prenom = data.prenom
        if (data.roles !== undefined) userData.roles = data.roles
        if (data.tel !== undefined) userData.tel = data.tel
        if (data.email !== undefined) userData.email = data.email
        if (data.etat !== undefined) userData.etat = data.etat
        if (data.emailSecondaire !== undefined) userData.emailSecondaire = data.emailSecondaire

        axios.patch(url, userData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(() => { done(true) })
          .catch(err => fail(err))
      })
    },
  },
}
