import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    insert({ commit }, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/documents', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    // eslint-disable-next-line no-empty-pattern
    getPj({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .get(`/documents/pj/${id}`)
          .then(response => {
            done(response.data)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    remove({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .delete(`/documents/${id}`)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },

  },
}
