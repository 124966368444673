import axios from '@axios'
import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state: {
    loggedUser: null,
    tokenValid: null,
    token: null,
  },
  getters: {
    loggedUser(state) { return state.loggedUser },
    fullName(state) {
      if (state.loggedUser) {
        return `${state.loggedUser.firstname} ${state.loggedUser.lastname}`
      }
      return ''
    },
    getUserType(state) { return state.accountType },
    getToken(state) { return state.token },
  },
  mutations: {
    setJWTTokenValid(state, value) { state.tokenValid = value },
    setToken(state, value) { state.token = value },
    setUser(state, value) { state.loggedUser = value },
    setAccountType(state, value) { state.accountType = value },
    setLoggeduser(state, value) { state.loggedUser = value },

  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    login({}, data) {
      console.log('Dans Login')
      return new Promise((resolve, reject) => {
        axios
          .post('/login_check', data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout({ commit }) {
      commit('setLoggeduser', null)
    },
    new({ commit }, data) {
      return new Promise((resolve, reject) => {
        const param = data
        param.unRequiredAuth = true
        axios
          .post('/auth/new', param)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    resetPassword({ commit }, { username }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/resetPassword', { email: username, unRequiredAuth: true })
          .then(response => {
            if (response.status === 201) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateProfil({ commit }, data) {
      return new Promise((done, fail) => {
        const url = '/auth/update'
        const userData = {
          nom: data.nom,
          prenom: data.prenom,
          societe: data.societe,
          tel: data.tel,
        }
        if (data.password) userData.password = data.password

        axios.post(url, userData)
          .then(() => { done(true) })
          .catch(err => fail(err))
      })
    },
  },
}
