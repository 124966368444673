import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/programmes/Client.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Nos programmes',
        breadcrumb: [
          {
            text: 'Programmes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profil',
      name: 'profil',
      component: () => import('@/views/auth/Profil.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Mon compte',
      },
    },
    {
      path: '/programmes/:id',
      name: 'programmes-detail',
      component: () => import('@/views/programmes/Detail.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Nos programmes',
            to: { name: 'programmes' },
            active: false,
          },
          {
            text: '',
            active: true,
          },
        ],
      },
    },
    {
      path: '/programmes',
      name: 'programmes',
      component: () => import('@/views/programmes/Client.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Nos programmes',
        breadcrumb: [
          {
            text: 'Programmes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/programmes/:id',
      name: 'admin-programmes-detail',
      component: () => import('@/views/programmes/Detail.vue'),
      meta: {
        requiresAuth: true,
        requireRole: 'ROLE_ADMIN',
        breadcrumb: [
          {
            text: 'Programmes',
            to: { name: 'admin-programmes' },
            active: false,
          },
        ],
      },
    },
    {
      path: '/admin/programmes',
      name: 'admin-programmes',
      component: () => import('@/views/programmes/Admin.vue'),
      meta: {
        requiresAuth: true,
        requireRole: 'ROLE_ADMIN',
        pageTitle: 'Gestion des programmes',
        breadcrumb: [
          {
            text: 'Gestion des programmes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/utilisateurs/:id',
      name: 'admin-utilisateurs-detail',
      component: () => import('@/views/utilisateurs/UtilisateurDetail.vue'),
      meta: {
        requiresAuth: true,
        requireRole: 'ROLE_ADMIN',
        breadcrumb: [
          {
            text: 'Gestion des utilisateurs',
            to: { name: 'admin-utilisateurs' },
            active: false,
          },
        ],
      },
    },
    {
      path: '/admin/utilisateurs',
      name: 'admin-utilisateurs',
      component: () => import('@/views/utilisateurs/Admin.vue'),
      meta: {
        requiresAuth: true,
        requireRole: 'ROLE_ADMIN',
        pageTitle: 'Gestion des utilisateurs',
        breadcrumb: [
          {
            text: 'Gestion des utilisateurs',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forget-password',
      name: 'forget-password',
      component: () => import('@/views/auth/ForgetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.requireRole !== undefined) {
    const user = JSON.parse(localStorage.getItem('userData'))

    if (!user.role.includes(to.meta.requireRole)) {
      next({ name: 'error-404' })
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
