import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-empty-pattern
    insert({ commit }, data) {
      return new Promise((done, fail) => {
        axios
          .post('/diffusion_listes', data)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    remove({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .delete(`/diffusion_listes/${id}`)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },

  },
}
