import axios from '@axios'

export default {
  namespaced: true,
  state: {
    projets: [],
    total: 0,
  },
  getters: {
    getProjets(state) {
      return state.projets
    },
    getTotal(state) {
      return state.total
    },
  },
  mutations: {
    setProjets: (state, value) => {
      state.projets = value
    },
    setTotal: (state, value) => {
      state.total = value
    },
  },
  actions: {
    fetchAll({ commit }, filter) {
      return new Promise((done, fail) => {
        let query = ''
        if (filter.page) {
          query += (query === '') ? `?page=${filter.page}` : `&page=${filter.page}`
        }
        if (filter.limit) {
          query += (query === '') ? `?limit=${filter.limit}` : `&limit=${filter.limit}`
        }
        if (filter.itemsPerPage) {
          query += (query === '') ? `?itemsPerPage=${filter.itemsPerPage}` : `&itemsPerPage=${filter.itemsPerPage}`
        }

        axios
          .get(`/projets${query}`)
          .then(response => {
            let projets = []
            projets = response.data['hydra:member'].map(projet => {
              const data = {
                value: projet['@id'],
                label: projet.nom,
                id: projet.id,
                projet: projet.nom,
                uri: projet['@id'],
                etat: projet.etat.toUpperCase(),
              }
              return data
            })
            commit('setProjets', projets)
            commit('setTotal', response.data['hydra:totalItems'])
            done({ projets, total: response.data['hydra:totalItems'] })
          })
          .catch(error => fail(error))
      })
    },
    fetchAllUsersByCompany({ commit, state }, company) {
      if (company) {
        if (company.users) {
          commit('setUsersByCompany', company.users.filter(user => user.status === true))
        } else {
          const comp = state.companies.filter(el => el.id === company.id)[0]
          commit('setUsersByCompany', comp.users.filter(user => user.status === true))
        }
      }
    },
    // eslint-disable-next-line no-empty-pattern
    insert({}, projet) {
      return new Promise((done, fail) => {
        axios
          .post('/projets', projet)
          .then(response => {
            done(response)
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    fetchOne({ commit }, id) {
      const url = `/projets/${id}`
      return new Promise((done, fail) => {
        axios.get(url)
          .then(response => {
            done(response.data)
          })
          .catch(err => fail(err))
      })
    },
    filter({ commit, state }, company) {
      if (state.companies && state.companies.length > 0) {
        const filteredCompany = state.companies.filter(item => company.toLowerCase().split(' ').every(v => item.company.toLowerCase().includes(v)))
        commit('setFilteredCompany', filteredCompany)
      }
    },
    update({ commit }, data) {
      return new Promise((done, fail) => {
        const url = `/projets/${data.id}`
        const projetData = {
          nom: data.nom,
          etat: data.etat,
        }
        axios.patch(url, projetData, { headers: { 'Content-Type': 'application/merge-patch+json' } })
          .then(() => { done(true) })
          .catch(err => fail(err))
      })
    },
  },
}
