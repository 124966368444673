import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './modules/auth'
import projets from './modules/projets'
import documents from './modules/documents'
import utilisateurs from './modules/utilisateurs'
import diffusionsListe from './modules/diffusionsListe'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    auth,
    projets,
    documents,
    utilisateurs,
    diffusionsListe,
  },
  strict: process.env.DEV,
})
