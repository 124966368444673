import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line
import router from '../router'

const config = require('../../config')

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: config.api.baseUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(conf => {
  const returnedConf = conf
  if (conf.params && conf.params.unRequiredAuth) delete returnedConf.headers.Authorization
  if (conf.data && conf.data.unRequiredAuth) delete returnedConf.headers.Authorization
  return returnedConf
})

// Add a response interceptor
axiosIns.interceptors.response.use(response => response,
  error => {
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')

      delete axios.defaults.headers.common.Authorization
      router.push('/login')
    }
    return error.response
  })

Vue.prototype.$http = axiosIns

export default axiosIns
